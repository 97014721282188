::-webkit-scrollbar {
	display: none;
}
::-webkit-font-smoothing {
	display: antialiased;
}
::-moz-osx-font-smoothing {
	display: grayscale;
}



/* Base scrollbar styles */
.simplebar-scrollbar {
    opacity: 1 !important;
}

.simplebar-mask *:focus {
    outline: none !important;
    border-color: transparent !important;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    background-color: #868998 !important;
    opacity: 0.4 !important;
    transition: opacity 0.2s ease-in-out;
}

/* Hover state */
.simplebar-track:hover .simplebar-scrollbar:before {
    opacity: 1.0 !important;
    background-color: #868998 !important;
    cursor: pointer !important;
}

/* Always show tracks */
.simplebar-track.simplebar-vertical,
.simplebar-track.simplebar-horizontal {
    visibility: visible !important;
}

/* Remove focus outline */
.simplebar-mask:focus-within {
    outline: none !important;
}

/* Optional: Add custom focus indicator if needed */
.simplebar-wrapper:focus-within {
    box-shadow: none !important;
    outline: none !important;
}

.simplebar-offset {
	display:flex;
}

.simplebar-content-wrapper {
	display: flex !important;
	width: 100% !important;
}

.simplebar-content {
	flex-grow: 1;
}

.toggleCircle {
	transition: transform 0.3s ease-out;
	fill: #e8eaed;
}

.lightMode .toggleCircle {
	transform: translateX(-15%);
	fill: #e8eaed;
}

.toggleSun {
	transform-origin: center center;
	transition: transform 0.6s cubic-bezier(0.11, 0.14, 0.29, 1.32);
	fill: #e8eaed;
}

.lightMode .toggleSun {
	transform: rotate(0.5turn);
	fill: #e8eaed;
}

.stealthMode .toggleSun {
	transform: rotate(2.75turn);
	fill: transparent;
	stroke: #e8eaed;
	stroke-width: 15px;
	transition: all 0.6s cubic-bezier(0.11, 0.14, 0.29, 1.32);
}

.stealthMode .toggleCircle {
	fill: transparent;
	transition: all 0.3s ease-out;
}

.stealthMode .toggleSun .cls-1 {
	fill: transparent;
	transition: all 0.3s ease-out;
}

.alert-enter {
	opacity: 0;
	top: 20;
	transform: scale(0.9);
}
.alert-enter-active {
	opacity: 1;
	top: 0;
	transform: translateX(0);
	transition: all 300ms ease-in;
}
.alert-exit {
	opacity: 1;
}
.alert-exit-active {
	opacity: 0;
	transform: scale(0.95);
	transition: all 40ms;
}

.my-node-enter {
	opacity: 0;
	transform: scale(0.9);
}
.my-node-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 200ms, transform 200ms;
}
.my-node-exit {
	opacity: 1;
}
.my-node-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition: opacity 200ms, transform 200ms;
}

.layoutJSON {
	background: #ddd;
	border: 1px solid black;
	margin-top: 0px;
	padding: 0px;
}
.columns {
	-moz-columns: 10px;
	-webkit-columns: 10px;
	columns: 10px;
}

.react-grid-layout {
	position: relative;
	transition: height 200ms ease;
}
.react-grid-item {
	box-sizing: border-box;
	border-radius: 3px;
	transition: all 200ms ease;
	transition-property: left, top;
}

.react-grid-item:not(.react-grid-placeholder) {
	border: 0px solid #ccc;
}

.react-grid-item.cssTransforms {
	transition-property: transform;
}

.react-grid-item.static {
	background: #cce;
}

.react-grid-item.resizing {
	z-index: 1;
	opacity: 0.9;
	will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
	transition: none;
	z-index: 3;
	will-change: transform;
}

.react-grid-item .text {
	font-size: 18px;
	text-align: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	height: 24px;
}

.react-grid-item .minMax {
	font-size: 12px;
}
.react-grid-item .add {
	cursor: pointer;
}
.react-grid-dragHandleExample {
	cursor: move; /* fallback if grab cursor is unsupported */
	cursor: -moz-grab;
	cursor: -webkit-grab;
	cursor: grab;
}
li b {
	font-size: 15px;
	line-height: 1px;
}

.react-grid-item.react-grid-placeholder {
	background: #0073e7;
	opacity: 0.3;
	transition-duration: 100ms;
	z-index: 2;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.react-grid-item > .react-resizable-handle {
	position: absolute;
	width: 20;
	height: 20px;
	bottom: 0;
	right: 0;
	background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=");
	background-position: bottom right;
	padding: 0 3px 3px 0;
	background-repeat: no-repeat;
	background-origin: content-box;
	box-sizing: border-box;
	cursor: se-resize;
}

.react-grid-item__title {
	font-size: 9px;
	color: #ccc;
	padding: 4px 0;
	text-align: center;
}
.react-grid-item__title:hover {
	cursor: move;
}
.react-grid-item__title .panel {
	box-shadow: none;
	border: none;
}
.react-grid-item__title .panel:focus {
	outline: none;
}
.react-grid-item__title .panel-heading {
	padding: 3px 0;
	background: #171819;
	border: none;
	color: rgba(255, 255, 255, 0.7);
}
.react-grid-item__title .panel-body {
	padding: 0 0 0 15px;
	background: #171819;
	color: rgba(255, 255, 255, 0.7);
}

.alert-enter {
	opacity: 0;
	transform: scale(0.9);
}
.alert-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 300ms, transform 300ms;
}
.alert-exit {
	opacity: 1;
}
.alert-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition: opacity 300ms, transform 300ms;
}

.square {
	width: 180px;
	height: 120px;
	transition: transform 100ms ease-out, border-radius 200ms ease-out;
}

.square .cover {
	width: 100%;
	height: 100px;
	border-radius: 3px 3px 0 0;
	background-size: cover !important;
	background-position: center !important;
}

.square .text {
	display: none;
	background: #181818;
	color: #fff;
	width: 100%;
	height: fit-content;
	padding: 5px;
	box-sizing: border-box;
	transition: opacity 300ms ease-out, border-radius 200ms ease-out;
	border-radius: 0 0 3px 3px;
}

.square:hover {
	border-radius: 3px;
	transform: scale(1.5);
	box-shadow: 0 0 2px #000a;
}

.square:hover .text {
	display: block;
}

.square.one .cover {
	background: url(https://firebasestorage.googleapis.com/v0/b/softcapdemo.appspot.com/o/companylogo%2Fairthings.png?alt=media?alt=media),
		#ffffff;
}

.square.one:hover {
	border-radius: 3px;
	transform: scale(2);
	box-shadow: 0 0 2px #000a;
	float: left;
	transform-origin: 50% 100%;
}

.square.one:hover .cover {
	background: url(https://firebasestorage.googleapis.com/v0/b/softcapdemo.appspot.com/o/companylogo%2Fairthings.png?alt=media),
		#ffffff;
}

.square.two .cover {
	background: url(https://firebasestorage.googleapis.com/v0/b/softcapdemo.appspot.com/o/companylogo%2Fentra.png?alt=media),
		#ffffff;
}

.square.two:hover .cover {
	background: url(https://firebasestorage.googleapis.com/v0/b/softcapdemo.appspot.com/o/companylogo%2Fentra.png?alt=media),
		#ffffff;
}

.square.three .cover {
	background: url(https://firebasestorage.googleapis.com/v0/b/softcapdemo.appspot.com/o/companylogo%2Fcolor.png?alt=media),
		#ffffff;
}

.square.three:hover .cover {
	background: url(https://firebasestorage.googleapis.com/v0/b/softcapdemo.appspot.com/o/companylogo%2Fcolor.png?alt=media),
		#ffffff;
}

.square.four .cover {
	background: url(https://firebasestorage.googleapis.com/v0/b/softcapdemo.appspot.com/o/companylogo%2Famazon.png?alt=media),
		#ffffff;
}

.square.four:hover .cover {
	background: url(https://firebasestorage.googleapis.com/v0/b/softcapdemo.appspot.com/o/companylogo%2Famazon.png?alt=media),
		#ffffff;
}

.square.five .cover {
	background: url(https://firebasestorage.googleapis.com/v0/b/softcapdemo.appspot.com/o/companylogo%2Fcrayon.png?alt=media),
		#ffffff;
}

.square.five:hover .cover {
	background: url(https://firebasestorage.googleapis.com/v0/b/softcapdemo.appspot.com/o/companylogo%2Fcrayon.png?alt=media),
		#ffffff;
}

.square.six .cover {
	background: url(https://firebasestorage.googleapis.com/v0/b/softcapdemo.appspot.com/o/companylogo%2Fsfl.png?alt=media),
		#ffffff;
}

.square.six:hover .cover {
	background: url(https://firebasestorage.googleapis.com/v0/b/softcapdemo.appspot.com/o/companylogo%2Fsfl.png?alt=media),
		#ffffff;
}

.square.seven .cover {
	background: url(https://firebasestorage.googleapis.com/v0/b/softcapdemo.appspot.com/o/companylogo%2Fzwipe.png?alt=media),
		#ffffff;
}

.square.seven:hover .cover {
	background: url(https://firebasestorage.googleapis.com/v0/b/softcapdemo.appspot.com/o/companylogo%2Fzwipe.png?alt=media),
		#ffffff;
}

.task {
	width: 240px;
	height: 200px;
	transition: transform 100ms ease-out, border-radius 200ms ease-out;
}

.task .cover {
	width: 100%;
	height: 200px;
	border-radius: 3px 3px 0 0;
	background-size: cover !important;
	background-position: center !important;
	background: rgba(255, 255, 255, 0.7);
}

.task .text {
	display: none;
	background: #181818;
	color: #fff;
	width: 100%;
	height: fit-content;
	padding: 5px;
	box-sizing: border-box;
	transition: opacity 300ms ease-out, border-radius 200ms ease-out;
	border-radius: 0 0 3px 3px;
}

.task:hover {
	border-radius: 3px;
	transform: scale(1.5);
	box-shadow: 0 0 2px #000a;
}

.task:hover .text {
	display: block;
}

.show-more-text {
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
	margin-left: 8px;
}

.show-more-text.fade-in {
	opacity: 1;
}

.show-more-container {
	display: flex;
	align-items: center;
	margin-left: 8px;
	text-transform: uppercase;
	font-weight: bold;
	transition: all 0.3s ease;
}

.arrow-container {
	transition: all 0.3s ease;
}

.arrow {
	font-size: 11px;
	font-weight: 500;
}

.show-more-container .arrow {
	margin-right: 4px;
}

.show-more-container:hover {
	cursor: pointer;
}

.show-more-container:hover .arrow-container {
	opacity: 1;
	transform: translateX(0);
}

.editable-box {
	cursor: pointer;
}

.editable-box:hover {
	background-color: #40444f;
	cursor: pointer;
}

.editable-box.light {
	cursor: pointer;
}

.editable-box.light:hover {
	background-color: #d5d8e1;
	cursor: pointer;
}

.editable-box-clicked:hover {
	background-color: transparent;
	cursor: default;
}

.editable-box-heading {
	cursor: pointer;
}

.editable-box-heading:hover {
	background-color: transparent;
	cursor: pointer;
}

.editable-box-heading.light {
	cursor: pointer;
}

.editable-box-heading.light:hover {
	background-color: transparent;
	cursor: pointer;
}

.editable-box-heading-clicked:hover {
	background-color: transparent;
	cursor: default;
}

/* CHAT / COMMENT BOX */
.RichEditor-root {
	/*border: 1px solid #ddd; */
	font-size: 14px;
	font-weight: 400;
	padding: 0px 0px 0px 0px;
	min-width: 200px;
	width: 100%;

	overflow-y: scroll;
	padding: 10px 6px 10px 6px;
}

/* Mobile styles */
@media only screen and (max-width: 450px) {
	.RichEditor-root {
		width: 100%;
	}
}

.RichEditor-editor {
	cursor: text;
	font-size: 15px;
}

/* Placeholder styles */
.RichEditor-editor .public-DraftEditorPlaceholder-root {
	color: grey !important; /* Set the color of the placeholder text to grey */
}
.RichEditor-editor .public-DraftEditor-content {
	margin: 0px 0px 0px 0px;
	padding: 0px;
}

.RichEditor-editor .public-DraftEditor-content {
	min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
	display: none;
}

.RichEditor-root.RichEditor-focused {
	border-bottom: 3px solid #5b5fc7; /* or whatever styling you wish to apply when focused */
}

/* Example class, replace with the actual class from the plugin */

/* Economics fadeeffects */
/* Fade Enter */
.fade-enter {
	opacity: 0;
	transform: scale(1.2);
	transition: opacity 200ms, transform 200ms;
}

.fade-enter-active {
	opacity: 1;
	transform: translateX(0%);
	transition: opacity 200ms, transform 200ms;
}

.fade-exit {
	opacity: 1;
	transform: translateY(0%);
	transition: opacity 200ms, transform 200ms;
}

.fade-exit-active {
	opacity: 0;
	transform: translateY(100%);
	transition: opacity 200ms, transform 200ms;
}

/*tombstones*/
/*tombstones*/
.fadeTomb-enter {
	opacity: 0;
	transform: scale(0);
}

.fadeTomb-enter-active {
	opacity: 1;
	transform: scale(1);
	transition: opacity 300ms, transform 300ms;
}

.fadeTomb-exit {
	opacity: 1;
	transform: scale(1);
}

.fadeTomb-exit-active {
	opacity: 0;
	transform: scale(0);
	transition: opacity 300ms, transform 300ms;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.fadeIn {
	animation: fadeIn 0.5s forwards;
}

.fadeOut {
	animation: fadeOut 0.5s forwards;
}
